import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from '../components/layout'

export default function Error404() {
  return <Layout>

              <Helmet>
                <title>404 - Page Not Found</title>
                <meta name="description" content="" />
              </Helmet>

            <div className="container text-center">
                <h1>Oops!</h1>
                <p>How'd you get here? Sorry, this page doesn't exist.</p>
                <Link to="/" className="button">Take me home please</Link><br />
                <img src={`404.jpg`} alt="A confused dog." />
            </div>
      </Layout>
}